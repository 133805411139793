.ErpSystem {
  font-family: Arial, sans-serif;
  color: white;
  background-color: black;
  padding: 200px;
  min-height: 100vh;
  margin-top: -150px;
  overflow: hidden;
}

.form-container h1, .accounts-container h2 {
  margin: 10px 0;
}

.user-form {
  display: flex;
  align-items: center;
  gap: 10px;
}

input[type="email"], button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
}

input[type="email"] {
  flex-grow: 1;
  border: 1px solid #ccc;
}

button {
  background-color: #fff;
  color: black;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

.accounts-container {
  margin-top: 20px;
}

.account-list {
  display: flex;
  width: 100%; /* Span the full width of its container */
}

.account-item {
  background-color: #333;
  padding: 10px;
  border-radius: 4px;
}
.organization-item {
  background-color: #333;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.organization-item h3 {
  margin-top: 0;
}

.organization-item p {
  margin: 5px 0;
}

.vertical-list .vertical-item {
  background-color: #333;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}
