/* Page Container */
.OrganizationAdminPage {
  padding: 20px;
  background-color: #000; /* Dark background for the entire page */
  color: white; /* Default text color */
}

/* Tabs and Active State */
.tab button {
  background-color: #555;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tab button.active {
  background-color: #142145;
  color: white;
}
/* For Firefox */
.MuiDataGrid-scrollbar {
  scrollbar-color: #555 #333; /* Thumb color and track color */
  scrollbar-width: thin;
}
.tab button:hover {
  background-color: #72ACF1;
}

/* DataGrid Container */
.tabcontent {
  width: 100%;
}

/* Rounded Button */
.rounded-button {
  background-color: #142145;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  padding: 10px 15px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* Bottom Div Container */
.bottom-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
}

/* Form Card */
.form-card {
  width: 33.33%;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  background-color: #142145;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.aligned-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

/* Input Fields */
.form-input {
  width: 80%;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  height: 30px;
  box-sizing: border-box;
  background-color: #333333;
  color: white;
}

.label {
  text-align: center;
  color: white;
}

/* Button Styling */
.form-button {
  width: 150px;
  height: 50px;
  padding: 12px;
  background-color: #142145;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
}

.form-button:hover {
  background-color: #0056b3;
}

/* Toggle Switch */
.toggle-switch {
  width: 60px;
  height: 30px;
  background-color: #555;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-slider {
  width: 28px;
  height: 28px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: left 0.3s ease;
}

.toggle-switch.active {
  background-color: #72ACF1;
}

.toggle-slider.active {
  left: 31px;
}

/* File Upload Card */
.file-card {
  width: 33.33%;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  background-color: #142145;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

/* Select Dropdown */
select.form-input {
  appearance: none;
  background: #333333 url('data:image/svg+xml;base64,...') no-repeat right 10px center;
  background-size: 12px;
  color: white;
}

/* Override Data Grid Styles */
.MuiDataGrid-root {
  border: none;
}

.MuiDataGrid-columnHeaders {
  background-color: #142145;
  color: white;
  font-weight: bold;
}

.MuiDataGrid-cell {
  color: white;
  background-color: #333333;
}

.MuiDataGrid-row:nth-child(even) {
  background-color: #444444;
}

.MuiDataGrid-row:hover {
  background-color: #555555;
}

.MuiDataGrid-footerContainer {
  background-color: #142145;
  color: white;
}

/* Override default pagination in Data Grid */
.MuiTablePagination-root {
  display: flex;
  justify-content: flex-end;
  color: white;
}
/* DataGrid Header */
.MuiDataGrid-columnHeaders {
  background-color: #142145;
  color: white;
}

/* DataGrid Footer */
.MuiDataGrid-footerContainer {
  background-color: #142145;
  color: white;
}

/* Pagination Text in Footer */
.MuiTablePagination-root {
  color: white;
}

/* Scrollbar Styling */
.MuiDataGrid-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #555 #333; /* Thumb color and track color */
}

.MuiDataGrid-scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-track {
  background: #333; /* Dark background for scrollbar track */
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-thumb {
  background-color: #555; /* Color for scrollbar thumb */
  border-radius: 6px;
  border: 3px solid #333; /* Adjust border for thumb */
}

/* DataGrid Header Styling */
.MuiDataGrid-columnHeaders {
  background-color: #142145 !important; /* Set dark blue color for header */
  color: white !important; /* Set header text to white */
  font-weight: bold !important;
  border-bottom: 1px solid #555555 !important; /* Add a border for separation */
}

/* Header Title Styling */
.MuiDataGrid-columnHeaderTitle {
  color: white !important; /* Ensure the text inside the header is white */
}

/* Footer Styling */
.MuiDataGrid-footerContainer {
  background-color: #142145 !important; /* Dark blue for footer */
  color: white !important; /* White text for footer */
}

/* Row Styling */
.MuiDataGrid-row {
  background-color: #333333 !important; /* Darker background for rows */
  color: white !important;
}

/* Cell Styling */
.MuiDataGrid-cell {
  border-color: #555555 !important; /* Cell border color for dark theme */
}

/* Pagination Styling */
.MuiTablePagination-root {
  color: white !important; /* White text for pagination controls */
}

/* Override the DataGrid container background color */
:root {
  --DataGrid-containerBackground: #142145 !important;
}
/* Override the DataGrid container background color */
.MuiDataGrid-root {
  --DataGrid-containerBackground: #142145 !important; /* Override the container background color */
}
/* File and Form Card Styling */
.file-card, .form-card {
  width: 33.33%;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  background-color: #142145;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

/* Aligned Form */
.aligned-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Input and Select Styling */
.form-input {
  width: 80%;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  height: 30px;
  box-sizing: border-box;
  background-color: #333;
  color: white;
}

/* Button Styling */
.form-button {
  width: 150px;
  height: 50px;
  padding: 12px;
  background-color: #142145;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.form-button:hover {
  background-color: #0056b3;
}

/* Toggle Button Styling */
.rounded-button {
  background-color: #142145;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  padding: 10px 15px;
  margin-top: 20px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* Bottom Div for Pagination */
.bottom-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
}
/* Filter Controls */
.filter-controls {
  display: flex;
  gap: 10px;
  padding: 10px;
  background-color: #142145; /* Match the dark blue color scheme */
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
}

.filter-select {
  width: 30%;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #555; /* Match the theme */
  border-radius: 4px;
  background-color: #333333; /* Dark background */
  color: white;
  appearance: none; /* Remove default dropdown styling */
  cursor: pointer;
}

.filter-input {
  flex: 1;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #555; /* Match the theme */
  border-radius: 4px;
  background-color: #333333; /* Dark background */
  color: white;
  height: 30px;
}

.filter-button {
  padding: 10px 20px;
  font-size: 14px;
  background-color: #142145; /* Dark blue button */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-button:hover {
  background-color: #0056b3; /* Slightly lighter blue for hover */
}

/* Filter Controls (Responsive Design) */
@media (max-width: 768px) {
  .filter-controls {
    flex-direction: column;
    gap: 5px;
  }

  .filter-select,
  .filter-input,
  .filter-button {
    width: 100%;
  }
}
/* Input Fields */
.form-input {
  width: 80%;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  height: 30px;
  box-sizing: border-box;
  background-color: #333333;
  color: white;
}

/* Aligned Form */
.aligned-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

/* Dialog Action Button */
.MuiButton-root {
  color: #72ACF1 !important; /* Custom color for dialog buttons */
}
